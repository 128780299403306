<template>
    <main class="bg-light">
        <div>
            <keep-alive>
                <top-nav/>
            </keep-alive>
            <div>
                <router-view/>
            </div>
        </div>
        <b-container class="mt-4" fluid>
            <b-row>
                <b-col>
                    <b-table-simple bordered hover small class="bg-white">
                        <b-thead>
                            <b-tr>
                                <b-th colspan="9">
                                    <div class="d-flex align-content-center align-items-center">
                                        <div class="p-1" >
                                            <b-form-input
                                                  size="sm"
                                                  placeholder="마스터 아이디를 입력하여 주세요"
                                                  style="width:300px"
                                                  v-model="filter.searchValue"
                                                  @keyup.enter="selectUserMember()"
                                            />
                                        </div>
                                        <div class="p-1">
                                            <b-button
                                                  variant="danger"
                                                  size="sm"
                                                  squared
                                                  v-on:click="selectUserMember()"
                                            >
                                                <font-awesome-icon
                                                      icon="search"
                                                />
                                            </b-button>
                                        </div>
                                    </div>
                                </b-th>
                            </b-tr>
                            <b-tr class="align-middle">
                                <b-th class="checkbox" @click="checkboxClick">
                                    <div>
                                        <b-form-checkbox
                                              v-model="allCheckBox"
                                              name="checkbox-all"
                                              value="all"
                                              @change="allCheck"
                                        />
                                    </div>
                                </b-th>
                                <b-th>
                                    아이디(이메일)
                                </b-th>
                                <b-th>
                                    닉네임
                                </b-th>
                                <b-th>
                                    역할
                                </b-th>
                                <b-th>
                                    Complete 영상보기
                                </b-th>
                                <b-th>
                                    이전 포인트<br/>(Sp/Bp)
                                </b-th>
                                <b-th>
                                    상태
                                </b-th>
                                <b-th>
                                    초대 수락날짜
                                </b-th>
                                <b-th>
                                    회수하기
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="members.length > 0">
                            <b-tr
                                  v-for="(member, index) in members"
                                  :key="`user-${index}`"
                            >
                                <b-td class="checkbox" @click="checkboxClick">
                                    <b-form-checkbox
                                          v-model="memberIdArray"
                                          :name="`checkbox-${index}`"
                                          :value="member._id"
                                          @change="rowCheckboxClick(member._id)"
                                    />
                                </b-td>
                                <b-td>

                                    <router-link
                                          :to="`/users/list/${member.userObjectId}/auth-history`"
                                    >
                                        {{ member.userId }}
                                    </router-link>
                                </b-td>

                                <b-td>
                                    {{ member.userInfo[0].nickName }}
                                </b-td>

                                <b-td >
                                    <template v-if="roleFlagArray[index].modifyFlag == false">
                                        <b-button variant="outline-primary" size="sm" @click="roleModifyShow(index)">
                                            {{ member.role }}
                                        </b-button>
                                    </template>
                                    <template v-else>
                                            <b-input-group class="w-auto form-inline">
                                                <b-form-input v-model="roleModify.role" placeholder="역할을 입력해주세요." size="sm" @keyup.enter="roleSave(index)"></b-form-input>
                                                <b-input-group-append>
                                                    <b-button size="sm" variant="success" @click="roleSave(index)">save</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                    </template>
                                </b-td>

                                <b-td>
                                    <template v-if="member.adList.length>0">
                                        <h5>
                                            <b-badge href="#" variant="info">
                                                {{ new Intl.NumberFormat().format(member.adList.length)}}
                                            </b-badge>
                                        </h5>

                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </b-td>

                                <b-td>
                                    <b-button variant="outline-success" size="sm" @click="pointHistoryModalOpen(index)">
                                        {{ new Intl.NumberFormat().format(member.samPoint) }}P / {{ new Intl.NumberFormat().format(member.bonusPoint) }}P
                                    </b-button>
                                </b-td>
                                <b-td>
                                    {{ replaceState(member.state) }}
                                </b-td>
                                <b-td>
                                    <template v-if="member.state == 'complete'">
                                        {{ new Date(member.inviteAcceptDate).toLocaleString() }}
                                    </template>

                                </b-td>
                                <b-td>
                                    <template v-if="member.state == 'complete'">
                                        <b-button variant="outline-danger" size="sm" @click="pointImportModal(member)">
                                          회수하기
                                        </b-button>
                                    </template>
                                    <template v-else>
                                        -
                                    </template>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-else>
                            <b-tr>
                                <b-td colspan="9">검색하신 마스터의 멤버가 없습니다.</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
                <hr/>
            </b-row>
            <b-row>
                <b-col>
                    <div class="d-flex justify-content-between align-items-center">
                        <small class="font-weight-bold text-primary">
                            {{total}}개가 검색되었습니다.
                        </small>
                           <b-pagination-nav
                                v-model="page"
                                :link-gen="linkGen"
                                :number-of-pages="totalPage"
                                first-number
                                last-number
                                size="sm"
                                align="right"
                            />
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-modal id="pointHistoryModal" :title="pointHistoryTitle">
            <b-container fluid>
                <b-table-simple class="hover small striped" sticky-header style="max-height:270px;">
                    <b-thead>
                        <b-tr>
                            <b-th class="text-center"> 일시</b-th>
                            <b-th class="text-center"> 구분</b-th>
                            <b-th class="text-center"> Sam 포인트</b-th>
                            <b-th class="text-center"> Bonus 포인트</b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <template v-if="pointHistory.length>0">
                            <b-tr v-for="(item, index) in pointHistory" :key="`history-${index}`" :class="item.status =='transfer' ? 'point-transfer' : 'point-withdraw'  ">
                                <b-td class>{{ new Date(item.regDate).toLocaleString() }}</b-td>
                                <b-td>{{ item.title }}</b-td>
                                <b-td class="text-center">{{ new Intl.NumberFormat().format(item.samPoint) }}P</b-td>
                                <b-td class="text-center">{{ new Intl.NumberFormat().format(item.bonusPoint) }}P</b-td>
                            </b-tr>
                        </template>
                        <template v-else>
                            <b-tr>
                                <b-td class="text-center" colspan="3">
                                    포인트 내역이 없습니다.
                                </b-td>
                            </b-tr>
                        </template>
                    </b-tbody>
                </b-table-simple>
            </b-container>
            <template #modal-footer>
                    <b-button
                          variant="primary"
                          size="sm"
                          class="float-right"
                          @click="pointHistoryModalClose()"
                    >
                        닫기
                    </b-button>
            </template>
        </b-modal>
        <pointImport :memberInfo="memberInfo" :selectUserInfo="selectUserInfo" @memberReload="selectUserMember"></pointImport>
    </main>
</template>

<script>
import pointImport from '@/components/member/pointImport'
export default {
    name: "corporateUsers",
    components: {
        pointImport
    },
    data: () => ({
        members: [],
        page: 1,
        total: 0,
        totalPage: 1,
        allCheckBox: [],
        memberIdArray: [],
        roleFlagArray:[],
        roleModify: {
           memberId: null,
           role: '',
        },
        pointHistoryTitle: '',
        pointHistory: [],
        filter: {
            searchValue: null,
            perPage: 10,
        },
        memberInfo: null,
        selectUserInfo: null
    }),
    created () {
        (async () => {
            const { searchValue , page, perPage } = this.$route.params;
            if (page && !isNaN(+page)) this.page = +page
            if (searchValue) this.filter.searchValue = searchValue;
            if (perPage && !isNaN(+perPage)) this.filter.perPage = +perPage;
            //this.filter.searchValue = 'dydrnrdks@naver.com';
            //cdawait this.selectUserMember();
        })()
    },
    methods: {
        async selectUserMember () {
            try{
                this.members = []
                const { data } = await this.axios.get(`/admin/users/member/list/${this.page}`,
                      {params:{
                              selectedId: this.filter.searchValue,
                              perPage: this.filter.perPage,
                          },
                      });
                const {result, members, error, total, totalPage, selectUserInfo} = data;
                if(result){
                    this.selectUserInfo = selectUserInfo;
                    this.members = members;
                    this.roleFlagArray = members.map( m =>{
                        return {
                            memberId: m._id,
                            modifyFlag: false,
                        }
                    });
                    this.total = total;
                    this.totalPage = totalPage;
                }else{
                    this.$bvToast.toast(`${error}`, {
                        title: 'ERROR',
                        autoHideDelay: 5000,
                        variant: 'danger',
                        appendToast: false,
                    });
                }

            }catch (e) {
                console.log(e);
            }
        },
        replaceState(state){
            return state;
            /*
            if(state == ' complete' ) return '초대 수락';
            else if(state == ' inviting' ) return '초대중';
            else if(state == ' invitationCancel' ) return '초대 취소';
            else if(state == ' overdue' ) return '기한초과';
            */
        },
        checkboxClick(e){
            const { target } = e;
            if(target.tagName !== 'INPUT' && target.tagName !== 'LABEL'){
                const checkbox = target.querySelector('input[type=checkbox]');
                const event = new MouseEvent('click');
                checkbox.dispatchEvent(event);
            }
        },
        linkGen (pageNum) {
          const filter = []
          filter.push(pageNum === 1 ? '' : `page=${pageNum}`)

          if (this.filter) {
            const filterData = Object.entries(this.filter).filter(([k, v]) => v).map(([k, v]) => {
              return `${k}=${v}`
            })
            if (filterData.length) filter.push(...filterData)
          }
          return `?${filter.join('&')}`
        },
        rowCheckboxClick(_id){
            if(this.memberIdArray.indexOf(_id) == -1) this.allCheckBox = [];
            if(this.memberIdArray.length === this.members.length) this.allCheckBox = ['all'];
        },
        allCheck(){
            if(this.allCheckBox.indexOf('all')>-1){
                this.memberIdArray = this.members.map(m => m._id);
            }else{
                this.memberIdArray = [];
            }
        },
        pointHistoryModalOpen(index){
            const { pointHistory, userInfo, userId } = this.members[index];
            this.pointHistoryTitle = `(${userId})이전포인트 상세내역`;
            this.pointHistory = pointHistory.sort((a, b) => {
              return a._id > b._id ? -1 : 1
            });
            this.$bvModal.show('pointHistoryModal');
        },
        pointImportModal (member) {
          this.memberInfo = member
          this.$bvModal.show('pointImportModal');
        },
        pointHistoryModalClose(){
            this.$bvModal.hide('pointHistoryModal');
        },
        roleModifyShow(index){
            const item = this.members[index];
            const { _id, role } = item;
            this.roleFlagArray[index].modifyFlag = true;
            this.roleModify.memberId = _id;
            this.roleModify.role = role;
        },

        async roleSave(index){
            try{
                const { data } = await this.axios.put(`/admin/users/member/role`,this.roleModify);
                const { result, error }  = data;
                if(result){

                    const modifyValue = this.roleModify.role;
                    this.members[index].role = modifyValue;
                    this.roleFlagArray[index].modifyFlag = false;
                    const item = this.members[index];
                    const { userId } = item;
                    this.$bvToast.toast(`[${userId}] 역할이 수정되었습니다. `, {
                        title: 'SUCCESS',
                        autoHideDelay: 5000,
                        variant: 'success',
                        appendToast: false,
                    });
                }else{
                    this.$bvToast.toast(`${error}`, {
                        title: 'ERROR',
                        autoHideDelay: 5000,
                        variant: 'danger',
                        appendToast: false,
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }
    },

}
</script>

<style scoped>
main {
    min-height: 100vh;
}
th, td {
    font-size: 0.8rem;
    vertical-align: middle;
}
tr.active {
    color: red;
    font-weight: bold;
    background-color: #F5F5F5;
}
.checkbox{
    cursor: pointer;
}
.point-transfer {
    color: rgb(0, 187, 238);
}
.point-withdraw  {
    color: rgb(255, 102, 128);
}
</style>
