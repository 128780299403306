<template>
  <b-modal id="pointImportModal" title="포인트 회수">
    <div class="modal-body pb-0" v-if="memberInfo">
      <div class="form-group row my-2">
        <label class="col-4 col-form-label">회수 할 사람 : </label>
        <div class="col-8 col-form-label">
          {{memberInfo.userId}}
        </div>
      </div>
      <div class="form-group row my-2">
        <label class="col-4 col-form-label">보유 포인트 :</label>
        <div class="col-8 col-form-label">
          {{memberInfo.userInfo[0].samPoint}} Sp / {{memberInfo.userInfo[0].bonusPoint}} Bp
        </div>
      </div>

      <div class="form-group row my-2">
        <label class="col-4 col-form-label">이전 포인트 :</label>
        <div class="col-8 col-form-label">
          {{memberInfo.samPoint}} Sp / {{memberInfo.bonusPoint}} Bp
        </div>
      </div>
      <div class="form-group row my-2">
        <label class="col-12 col-form-label">회수 포인트 </label>
        <ul>
          <li>
            <div class="d-flex bd-highlight">
              <div class="p-2">
                Sam Point :
              </div>
              <div>
                <b-input-group>
                  <input type="number" class="form-control" aria-label="" aria-describedby="" v-model="samPoint">
                </b-input-group>
              </div>
            </div>
          </li>
          <li class="mt-2">
            <div class="d-flex bd-highlight">
              <div class="p-2">
                Bonus Point :
              </div>
              <div>
                <input type="number" class="form-control inputColor" v-model="bonusPoint">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <template #modal-footer>
      <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="save()"
      >
        회수
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "pointImport",
  props: {
    memberInfo: {
      type: Object,
      default: null
    },
    selectUserInfo: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    samPoint: 0,
    bonusPoint: 0
  }),
  methods: {
    pointImportModalClose () {
      this.samPoint = 0
      this.bonusPoint = 0
      this.$bvModal.hide('pointImportModal');
    },

    async save () {
      if (this.selectUserInfo === null) {
        return this.$bvToast.toast(`마스터 정보를 불러오지 못하였습니다. 개발 담당자에게 문의 주세요`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        });
      }

      if (this.samPoint === null || this.bonusPoint === null) {
        return this.$bvToast.toast(`입력칸에 빈칸을 입력할 수 없습니다. 회수할 포인트가 없으면 0 을 입력하여 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        });
      }

      if (this.samPoint === 0 && this.bonusPoint === 0) {
        return this.$bvToast.toast(`SamPoint, bonusPoint 둘다 0을 입력할 수 없습니다. 하나라도 10포인트 이상 입력하여 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        });
      }

      if (0 < this.samPoint && this.samPoint < 10) {
        return this.$bvToast.toast(`SamPoint는 최소 10Sp 이상 입력해야 합니다.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        });
      }

      if (0 < this.bonusPoint && this.bonusPoint < 10) {
        return this.$bvToast.toast(`bonusPoint는 최소 10Sp 이상 입력해야 합니다.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false
        });
      }

      try {
        const {data} = await this.axios({
          url: `/admin/member/transfer/${this.memberInfo._id}`,
          method: 'POST',
          data: {
            samPoint: this.samPoint,
            bonusPoint: this.bonusPoint,
            selectUserInfo: this.selectUserInfo
          }
        });

        const {result, error} = data;
        if (!result) return this.$bvToast.toast(`${error}`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false,
        });

        this.$bvToast.toast(`포인트를 회수하였습니다.`, {
          title: 'SUCCESS',
          autoHideDelay: 3000,
          variant: 'success',
          appendToast: false
        });
        this.pointImportModalClose()
        this.$emit('memberReload', true)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
